<template>
  <div>
    <div class="upload-text" v-if="!disabled">
      点击上传
      <input
        v-if="!multiple"
        type="file"
        class="upload-input"
        @change="selectFile($event)"
      >
      <input
        v-if="multiple"
        type="file"
        class="upload-input"
        multiple="multiple"
        @change="selectFile($event)"
      >
    </div>
    <div
      v-for="item in imgTempArr"
      :key="item.src"
      class="upload-list__item"
    >
      <img
        v-show="priview"
        :src="item.src"
        style="width: 50px;height: 50px;"
        :alt="item.title"
      >
      <div class="upload-list__item-info">
        <div>{{ item.title }}</div>
        <div
          v-if="item.errorText"
          class="error-text"
        >
          {{ item.errorText }}
        </div>
        <el-progress :percentage="item.progress"></el-progress>
      </div>
    </div>
  </div>
</template>
<script>
import { upload } from '@/utils/oss'
/**
 * todo: 图片删除和放大功能,access存储到后端
 */
export default {
  name: 'LoadImgOss',
  props: {
    // 进度条
    compress: {
      type: Number,
      default: 0.8
    },
    // 是否上传多图
    multiple: {
      type: Boolean,
      default: false
    },
    // 是否预览
    priview: {
      type: Boolean,
      default: true
    },
    defaultUrl: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      imgUrl: '',
      imgArr: [],
      imgTempArr: []
    }
  },
  watch: {
    defaultUrl(item) {
      this.imgTempArr.push({ src: item, progress: 1 })
    }
  },
  methods: {
    // 断点续传
    selectFile(e) {
      let _this = this
      // 每次重新选择图片时，重置预览图片列表数据
      this.imgTempArr = []
      this.imgArr = []
      for (let i = 0; i < e.target.files.length; i++) {
        let fileName = e.target.files[i].name
        let reader = new FileReader()
        reader.readAsDataURL(e.target.files[i])// 读取file
        reader.onload = function(data) {
          let image = new Image()// 新建一个img标签（还没有嵌入dom节点）
          let currentFile = null
          image.src = data.target.result // 将图片的路径设置成file路径
          _this.imgTempArr.push({
            title: fileName,
            src: data.target.result,
            progress: 0,
            index: i,
            errorText: '' // 上传失败文案
          })
          image.onload = function() {
            // 压缩的思路是创建一个图片，将file等于这个图片，然后创建一个canvas图层，将canvas等比例缩放，然后用canvas的drawImage将图片与canvas结合起来
            // 然后将canvas的base64转成file即可
            // let _data = _this.onImgCompression(image)
            currentFile = _this.dataURLtoFile(image.src, fileName)
            _this.multipartUpload(currentFile, i)
          }
        }
      }
    },
    // 压缩图片
    onImgCompression(img) {
      let canvas = document.createElement('canvas')
      let ctx = canvas.getContext('2d')
      let initSize = img.src.length
      let width = img.width
      let height = img.height
      canvas.width = width
      canvas.height = height
      // 铺底色
      ctx.fillStyle = '#fff'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(img, 0, 0, width, height)
      // 进行压缩
      let compress = this.compress || 0.8 // 压缩率
      return canvas.toDataURL('image/jpeg', compress)
    },
    // 将裁剪base64的图片转换为file文件
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','); var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1]); var n = bstr.length; var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    // 定义上传方法
    async multipartUpload(file, index) {
      this.loading = true;
      upload(file).then((res)=>{
        const url = res.url.split('?')[0];
        const imgObj = [{url: url, title: file.name}]
        this.imgTempArr[index].progress = 100;
        this.$emit('onSuccess', imgObj);
      }, ()=> {
        this.imgTempArr[index].progress = 0
        this.imgTempArr[index].errorText = '上传失败，请重新上传'
      }).finally(()=>{
        this.loading = false;
      })
    }
  }
}
</script>
<style scoped>
.upload-text{
  width: 80px;
  padding: 0 15px;
  font-size: 12px;
  border-radius: 3px;
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  position: relative;
}
.upload-input{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: Alpha(opacity=0);
  -moz-opacity:0;
  opacity: 0;
  cursor: pointer;
}
.upload-list__item{
  display: flex;
  margin-top: 10px;
}
.upload-list__item-info{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
}
.error-text{
  color: red;
}
</style>
