var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.disabled
        ? _c("div", { staticClass: "upload-text" }, [
            _vm._v(" 点击上传 "),
            !_vm.multiple
              ? _c("input", {
                  staticClass: "upload-input",
                  attrs: { type: "file" },
                  on: {
                    change: function ($event) {
                      return _vm.selectFile($event)
                    },
                  },
                })
              : _vm._e(),
            _vm.multiple
              ? _c("input", {
                  staticClass: "upload-input",
                  attrs: { type: "file", multiple: "multiple" },
                  on: {
                    change: function ($event) {
                      return _vm.selectFile($event)
                    },
                  },
                })
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._l(_vm.imgTempArr, function (item) {
        return _c("div", { key: item.src, staticClass: "upload-list__item" }, [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.priview,
                expression: "priview",
              },
            ],
            staticStyle: { width: "50px", height: "50px" },
            attrs: { src: item.src, alt: item.title },
          }),
          _c(
            "div",
            { staticClass: "upload-list__item-info" },
            [
              _c("div", [_vm._v(_vm._s(item.title))]),
              item.errorText
                ? _c("div", { staticClass: "error-text" }, [
                    _vm._v(" " + _vm._s(item.errorText) + " "),
                  ])
                : _vm._e(),
              _c("el-progress", { attrs: { percentage: item.progress } }),
            ],
            1
          ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }